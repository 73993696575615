import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import * as dat from 'lil-gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { gsap } from 'gsap'


/**
 * Loaders
 */
 const loadingBarElement = document.querySelector('.loading-bar')
 const loadingManager = new THREE.LoadingManager(
     // Loaded
     () =>
     {
         // Wait a little
         window.setTimeout(() =>
         {
             // Animate overlay
             gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0, delay: 1 })
 
             // Update loadingBarElement
             loadingBarElement.classList.add('ended')
             loadingBarElement.style.transform = ''
         }, 500)
     },
 
     // Progress
     (itemUrl, itemsLoaded, itemsTotal) =>
     {
         // Calculate the progress and update the loadingBarElement
         const progressRatio = itemsLoaded / itemsTotal
         loadingBarElement.style.transform = `scaleX(${progressRatio})`
     }
 )


/**
 * Base
 */
// Debug
// const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const matcapTexture = textureLoader.load('textures/matcaps/3.png')
const logo = textureLoader.load('textures/logo/logo.png')

/**
 * Plane Logo
 */
 var planeGeometry = new THREE.PlaneGeometry(5, 5, 1, 1);
 var planeMaterial = new THREE.MeshLambertMaterial( { map: logo } );
 planeMaterial.transparent = true
 //var planeMaterial = new THREE.MeshLambertMaterial({color: 0xffffff});
 var plane = new THREE.Mesh(planeGeometry, planeMaterial);
 plane.receiveShadow = true;
 // rotate and position the plane
 //plane.rotation.x = -0.5 * Math.PI;
 plane.position.set(0,-0.355,-.5);
 // add the plane to the scene
 scene.add(plane);
 planeMaterial.side = THREE.DoubleSide;


//  var planeBlackGeometry = new THREE.PlaneGeometry(50, 50, 1, 1);
//  var planeBlackMaterial = new THREE.MeshBasicMaterial({color:'black'});
 
//  //var planeMaterial = new THREE.MeshLambertMaterial({color: 0xffffff});
//  var planeBlack = new THREE.Mesh(planeBlackGeometry, planeBlackMaterial);

//  // rotate and position the plane
//  //plane.rotation.x = -0.5 * Math.PI;
//  planeBlack.position.set(0,0,-.5);
//  // add the plane to the scene
//  scene.add(planeBlack);

 /**
  * Lights
  */

  var light = new THREE.AmbientLight(0xffffff, 1)
 
  scene.add(light);

/**
 * Fonts
 */
// const fontLoader = new FontLoader()

// fontLoader.load(
//     '/fonts/helvetiker_regular.typeface.json',
//     (font) =>
//     {
//         // Material
//         const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })

//         // Text
//         const textGeometry = new TextGeometry(
//             'Coming Soon',
//             {
//                 font: font,
//                 size: 0.4,
//                 height: 0.02,
//                 curveSegments: 12,
//                 bevelEnabled: true,
//                 bevelThickness: 0.003,
//                 bevelSize: 0.02,
//                 bevelOffset: 0,
//                 bevelSegments: 1
//             }
//         )
//         textGeometry.center()

//         const text = new THREE.Mesh(textGeometry, material)
//         text.rotation.y = Math.PI
//         text.position.z = -1
//         scene.add(text)

//         // Donuts
//         const donutGeometry = new THREE.TorusGeometry(0.3, 0.2, 32, 64)

//         for(let i = 0; i < 100; i++)
//         {
//             const donut = new THREE.Mesh(donutGeometry, material)
//             donut.position.x = (Math.random() - 0.5) * 10
//             donut.position.y = (Math.random() - 0.5) * 10
//             donut.position.z = (Math.random() - 0.5) * 10
//             donut.rotation.x = Math.random() * Math.PI
//             donut.rotation.y = Math.random() * Math.PI
//             const scale = Math.random()
//             donut.scale.set(scale, scale, scale)

//             //scene.add(donut)
//         }
//     }
// )

/**
 * Models
 */
 const gltfLoader = new GLTFLoader(loadingManager)

 gltfLoader.load(
    '/models/Cards/club.glb',
    (gltf) =>
    {
        gltf.scene.children[0].scale.set(0.0125,0.01,0.01)
        gltf.scene.children[0].rotation.set(0,0,Math.PI/5)

        gltf.scene.children[0].position.set(-1,1,-0.1003)
        scene.add(gltf.scene.children[0])
    }
)
gltfLoader.load(
    '/models/Cards/diamond.glb',
    (gltf) =>
    {
        gltf.scene.children[0].scale.set(0.0125,0.01,0.01)
        gltf.scene.children[0].rotation.set(0,0,Math.PI/10)
        gltf.scene.children[0].position.set(-.4,1.25,-0.1002)
        scene.add(gltf.scene.children[0])
    }
)
gltfLoader.load(
    '/models/Cards/hearts.glb',
    (gltf) =>
    {
        gltf.scene.children[0].scale.set(0.0125,0.01,0.01)
        gltf.scene.children[0].rotation.set(0,0,-Math.PI/10)

        gltf.scene.children[0].position.set(.4,1.25,-0.1001)
        scene.add(gltf.scene.children[0])
    }
)
gltfLoader.load(
    '/models/Cards/spade.glb',
    (gltf) =>
    {
        gltf.scene.children[0].scale.set(0.0125,0.01,0.01)
        gltf.scene.children[0].rotation.set(0,0,-Math.PI/5)

        gltf.scene.children[0].position.set(1,1,-0.1)
        scene.add(gltf.scene.children[0])
    }
)

/**
 * Overlay
 */

const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)
const overlayMaterial = new THREE.ShaderMaterial({
    // wireframe: true,
    transparent: true,
    uniforms:
    {
        uAlpha: { value: 1 }
    },
    vertexShader: `
        void main()
        {
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform float uAlpha;

        void main()
        {
            gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
        }
    `
})
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
scene.add(overlay)


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera

// Group
const cameraGroup = new THREE.Group()
scene.add(cameraGroup)

const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 0
//camera.position.y = 1
camera.position.z = 5
scene.add(camera)
cameraGroup.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.enabled = false
// controls.autoRotate = true
// controls.autoRotateSpeed = 5

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.setClearColor( 0xffffff, 0);


/**
 * Cursor
 */
 const cursor = {}
 cursor.x = 0
 cursor.y = 0

 window.addEventListener('mousemove', (event) =>
 {
     cursor.x = event.clientX / sizes.width - 0.5
     cursor.y = event.clientY / sizes.height - 0.5
 
    
 })


/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0
const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime
    // Update controls
    controls.update()

    const parallaxX = cursor.x * 2
    const parallaxY = - cursor.y * 2
    cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * 5 * deltaTime
    cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * 5 * deltaTime
    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()